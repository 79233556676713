import * as React from "react"
import { graphql } from "gatsby"
import Container from '../components/Container'
import IndentedContent from '../components/helpers/IndentedContent'
import ContentPage from '../components/layout/ContentPage'
import SectionEntry from '../components/SectionEntry'
import SectionEntryTitle from '../components/SectionEntryTitle'
import BlogPost from '../components/BlogPost'
import SEO from '../components/seo'

// markup
const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <BlogPost key={edge.node.id} post={edge.node} />)

  return (
    <main>
      <SEO title="Blog" />

      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>

      <ContentPage>
        <Container>
          <IndentedContent>
            <SectionEntry>
              <SectionEntryTitle>Blog</SectionEntryTitle>
            </SectionEntry>

            {Posts}

          </IndentedContent>
        </Container>
      </ContentPage>
    </main>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            slug
            title
          }
        }
      }
    }
  }
`
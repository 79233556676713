import * as React from "react"
import { Link } from 'gatsby';
import Feature from './Feature';
import './BlogPost.scss';

const BlogPost = ({ post }) => {
  return (
    <div className="blog-post">
      <Feature title={<Link to={"/posts/"+post.frontmatter.slug}>{post.frontmatter.title}</Link>}>
        {post.excerpt}
      </Feature>
    </div>
  );
};

export default BlogPost;
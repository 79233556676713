import * as React from "react"

const Feature = (props) => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',

    ...props?.containerStyles || {},
  };

  const textStyles = {
    color: 'rgba(29,49,80,.5)',
    fontSize: '0.875rem',
  };

  return (
    <div style={containerStyles}>
      <h3>{props.title}</h3>

      <div style={textStyles}>
        {props.children}
      </div>
    </div>
  )
}

export default Feature;